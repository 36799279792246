import { memo } from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'

import { CITY_MAP } from 'config/options'
import { moneyFormat } from 'utils/util'
import { handleUniversalStudios } from 'utils/product'

import Card from 'common/card/Card'
import CardSection from 'common/card/CardSection'
import Img from 'common/Img'
import Badge from 'common/Badge'

import UniversalProductImg from 'public/universal/universal_product_cn.jpg'
import { objectToJSX, jsDOMPurify } from 'utils/htmlParser'

const ProductInfoCard = (props) => {
  const {
    className,
    imgClassName,
    id,
    productInfo,
    imgHeight,
    stripDescription,
  } = props

  const productCity = productInfo.city?.map(cityId => CITY_MAP.find(city => city.value === cityId)?.label || '').join('、')
  const isUniversalProduct = handleUniversalStudios(productInfo.title)
  const ImgTopLeftContent = () => (
    <div className={classReader('m-2')}>
      <Badge
        className={classReader({ IndexStyled: ['info-card__location'] })}
        color="white"
        textColor="gray-8"
        rounded
      >
        <i className={classReader('icon icon-location icon-sm icon-primary mr-1')} />
        <span>{productCity}</span>
      </Badge>
    </div>
  )

  const stripHTMLTags = (str) => {
    return str.replace( /(<([^>]+)>)/ig, '')
  }

  // const ImgTopRightContent = () => (
  //   <div
  //     className={classReader('m-2', {IndexStyled:'info-card__favorite'})}
  //     data-active={productInfo.favorite === true}
  //     onClick={() => { console.log('click favorite') }}
  //   >
  //     <i className={
  //       classReader(
  //         'icon icon-sm icon-white m-none',
  //         productInfo.favorite === true ? 'icon-favorite-full' : 'icon-favorite'
  //       )}
  //     />
  //   </div>
  // )
  return (
    <Link
      className={classReader('w-100', { pure: 'gtm-home-product' })}
      prefetch={false} 
      href={productInfo?.productNumber ? `/product/${productInfo?.productNumber}` : '/'}
      aria-label={productInfo?.title}
      passHref>
      <Card className={classReader(
        'shadow-0', { IndexStyled: 'card--info' }, className,
      )} id={id}>
        <div className={classReader('overflow-hidden')}>
          <Img
            className={classReader('rounded-ec', { IndexStyled: ['img'] })}
            styleName="IndexStyled"
            src={isUniversalProduct ? UniversalProductImg : productInfo?.mediaPath}
            height={imgHeight}
            alt=""
            ratio={4 / 2.9776}
            ratioClassName={imgClassName}
            topLeftContent={Boolean(productCity) && !isUniversalProduct && <ImgTopLeftContent />}
            defaultBg
          /* phase2 */
          // topRightContent={<ImgTopRightContent />}
          />

          <CardSection className={classReader('pt-2 pb-0 px-1')}>
            {Boolean(productInfo.title) && (
              <h3 className={classReader('mt-0 ellipsis-2-lines', { IndexStyled: ['info-card__title'] })}>
                {productInfo.title}
              </h3>
            )}

            <div data-type="description" className={classReader('my-2')}>
              {Boolean(productInfo.description) && (
                <p className={classReader('ellipsis-2-lines m-0', { IndexStyled: ['info-card__description'] })}>
                  {stripDescription ? jsDOMPurify(stripHTMLTags(productInfo.description)) : productInfo.description}
                </p>
              )}
            </div>

            <div className={classReader({ IndexStyled: ['info-card__tag'] })}>
              {productInfo.isCancelFree === true && (
                <Badge className={classReader({ IndexStyled: ['tag--padding'] })} color="secondary">
                  免費取消
                </Badge>
              )}

              {productInfo.isInstantBooking === true && (
                <Badge className={classReader({ IndexStyled: ['tag--padding'] })} color="secondary">
                  即買即用
                </Badge>
              )}
            </div>
          </CardSection>
        </div>

        <CardSection className={classReader('py-0 px-1 overflow-hidden text-nowrap', { IndexStyled: ['info-card__price'] })}>
          {Boolean(productInfo.price) && productInfo.price !== productInfo.minPrice && (
            <span className={classReader({ IndexStyled: ['info-card__price--prev'] })}>
              {moneyFormat(productInfo.price)}
            </span>
          )}

          {Boolean(productInfo.minPrice) && (
            <>
              <span className={classReader({ IndexStyled: ['info-card__price--main'] })}>
                {moneyFormat(productInfo.minPrice)}
              </span>
              起
            </>
          )}
        </CardSection>
      </Card>
    </Link>
  )
}

ProductInfoCard.propTypes = {
  className: PropTypes.string,
  imgClassName: PropTypes.string,
  id: PropTypes.string,
  productInfo: PropTypes.object,
  imgHeight: PropTypes.number,
  stripDescription: PropTypes.bool,
}

ProductInfoCard.defaultProps = {
  productInfo: {},
  imgClassName: 'd-block d-lg-none', 
  stripDescription: false,
}

export default memo(ProductInfoCard)
