import {
  memo,
  useState,
  useEffect, 
} from 'react'
  
import classReader from 'utils/classReader'
import PropTypes from 'prop-types'
import Slider from 'common/Slider'
import ProductInfoCard from 'page-components/home/ProductInfoCard'
import SwiperButton from 'page-components/home/SwiperButton'
import product from 'apis/product/product'

const ProductRecommend = (props) => {
  const {
    buttonOutside,
    className,
    gridClassName,
    slider,
    total,
    title,
  } = props

  const [recommendProducts, setRecommendProducts] = useState([])

  useEffect(() => {
    const getRecommendProducts = async () => {
      try {
        const { success, data } = await product.getRecommendProducts(total)
        if (success){
          
          const products = data.products?.map(product => ({
            ...product,
            mediaPath: product.mediaPath[0],
          }))

          setRecommendProducts(products || [])
        }
      } catch (e) {
        setRecommendProducts([])
      } 
    }
    
    getRecommendProducts()
  }, [])

  return (<>
    {recommendProducts.length > 0 && <>
      <div className={classReader('product-recommend', className)}>
       
        <h2 className={classReader('section__title section__title--separator')}>
          <i className={classReader('icon icon-heart icon-red mr-1')} />
          { title } 
        </h2>
        
        { slider ? (
          <Slider
            className={classReader('product-recommend__slider')}
            spaceBetween={20}
            slidesPerView={4}
            customLeftNavigation={(prevFunc) => (<>
              <div className={classReader('slider')}>
                <SwiperButton icon="stroke-arrow-left" onClick={prevFunc} />
              </div>
            </>)}
            customRightNavigation={(nextFunc) => (
              <SwiperButton icon="stroke-arrow-right" onClick={nextFunc} />
            )}
            buttonOutside={buttonOutside}
            breakpoints={{
              992: {
                slidesPerView: 4,
                slidesPerGroup: 3,
              },
              768: {
                slidesPerView: 3.5,
                slidesPerGroup: 2,
                spaceBetween: 20,
              },
              480: {
                slidesPerView: 2.5,
                slidesPerGroup: 1,
                spaceBetween: 10,
              },
              0: {
                slidesPerView: 1.8,
                slidesPerGroup: 1,
                spaceBetween: 10,
              },
            }}
            slides={recommendProducts.map((item, index) => ({
              id: `slider${index}`,
              content: (<ProductInfoCard 
                productInfo={item} 
                imgClassName="d-block" 
                stripDescription={true}
              />
              ),
            }))}
          />
        ) : (
          <div className={classReader('row product-recommend__list')}>
            {recommendProducts.map((item, index) => 
              <div key={index} className={classReader('product-recommend__item', gridClassName)}>
                <ProductInfoCard
                  productInfo={item}
                  imgClassName="d-block"
                  stripDescription={true}
                />
              </div>)}
          </div>
        )}
      </div>
    </>}
  </>)
}
  
ProductRecommend.propTypes = {
  buttonOutside: PropTypes.bool,
  className: PropTypes.string,
  gridClassName: PropTypes.string,
  slider: PropTypes.bool,
  total: PropTypes.number,
  title: PropTypes.string,
}
  
ProductRecommend.defaultProps = {
  buttonOutside: true,
  className: '',
  gridClassName: 'col-12 col-md-4 col-lg-3',
  slider: false, 
  total: 5,
  title: '猜你喜歡',
}
  
export default memo(ProductRecommend)