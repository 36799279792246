import { memo } from 'react'
import Image from 'next/image'

import classReader from 'utils/classReader'
import PropTypes from 'prop-types'
import Slider from 'common/Slider'
import SwiperButton from 'page-components/home/SwiperButton'
import ProductInfoCardByKeyword from 'src/page-components/home/ProductInfoCardByKeyword'
import Link from 'next/link'
import Button from 'common/Button'

const ProductByKeyword = (props) => {
  const {
    buttonOutside,
    className,
    gridClassName,
    slider,
    title,
    flag,
    url,
    recommendData,
  } = props
  
  return (<>
    {recommendData.length > 0 && <>
      <div className={classReader('product-recommend', className)}> 
        <div className={classReader('d-flex align-items-center')}>
          <h2 className={classReader('section__title section__title--separator')}>
            <Image className={classReader('header__app__image mr-1', { 'mr-2': flag !== 'pineapple.svg' })}
              width={30}
              height={30}
              alt="flag"
              src={`/flag/${flag}`}
              priority
            />
            { title } 
          </h2>
          <div className={classReader({ RecommendByKeywordStyled: 'mb-20' })}>
            <Link href={url} passHref>
              <Button
                className={classReader('head__button')}
                label="查看更多"
                color="primary"
                textColor="primary"
                outline={true}
                unelevated
              />
            </Link>
          </div>
        </div>
        { slider ? (
          <Slider
            className={classReader('product-recommend__slider')}
            spaceBetween={20}
            slidesPerView={2}
            customLeftNavigation={(prevFunc) => (<>
              <div className={classReader('slider')}>
                <SwiperButton icon="stroke-arrow-left" onClick={prevFunc} />
              </div>
            </>)}
            customRightNavigation={(nextFunc) => (
              <SwiperButton icon="stroke-arrow-right" onClick={nextFunc} />
            )}
            buttonOutside={buttonOutside}
            breakpoints={{
              1200: {
                slidesPerView: 3.6,
                slidesPerGroup: 3,
              },
              992: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              768: {
                slidesPerView: 2.5,
                slidesPerGroup: 2,
                spaceBetween: 20,
              },
              480: {
                slidesPerView: 1.8,
                slidesPerGroup: 1,
                spaceBetween: 10,
              },
              320: {
                slidesPerView: 1.1,
                slidesPerGroup: 1,
                spaceBetween: 10,
              },
              0: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 10,
              },
            }}
            slides={recommendData.map((item, index) => ({
              id: `slider${index}`,
              content: (<div className={classReader({ RecommendByKeywordStyled: 'window' }, { RecommendByKeywordStyled: item.isLocal ? 'window-local' : '' })}>
                <div className={classReader({ RecommendByKeywordStyled: 'curtain' }, { RecommendByKeywordStyled: item.isLocal ? 'curtain-local' : '' })}>
                  <div className={classReader({ RecommendByKeywordStyled: 'curtain-inside' })}></div>
                </div>
                <ProductInfoCardByKeyword 
                  className={classReader({ RecommendByKeywordStyled: 'window--border-radius' })}
                  productInfo={item} 
                  imgClassName="d-block" 
                  stripDescription={true}
                />
              </div>
              ),
            }))}
          />
        ) : (
          <div className={classReader('row product-recommend__list')}>
            {recommendData.map((item, index) => 
              <div key={index} className={classReader('product-recommend__item', gridClassName)}>
                <ProductInfoCardByKeyword
                  productInfo={item}
                  imgClassName="d-block"
                  stripDescription={true}
                />
              </div>)}
          </div>)}
      </div>
    </>}
  </>)
}
    
ProductByKeyword.propTypes = {
  buttonOutside: PropTypes.bool,
  className: PropTypes.string,
  gridClassName: PropTypes.string,
  slider: PropTypes.bool,
  total: PropTypes.number,
  title: PropTypes.string,
  flag: PropTypes.string,
  url: PropTypes.string,
  recommendData: PropTypes.array,
}
    
ProductByKeyword.defaultProps = {
  buttonOutside: true,
  className: '',
  gridClassName: 'col-12 col-md-4 col-lg-3',
  slider: false, 
  title: '',
  flag: '',
  url: '',
  recommendData: [],
}
    
export default memo(ProductByKeyword)