import {
  useState, useEffect, Fragment, 
} from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import $isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'
import {
  LOCAL_STORAGE,
  ENV_INFO,
  SESSION_STORAGE,
} from 'config/config'
import { WEB_TITLE, HEAD_DEFAULT_DATA } from 'config/head'
import { THEME_OPTIONS } from 'config/options'
import { RECOMMEND_KEYWORDS } from 'config/page/home'
import { handleHeadParams, getJsonData } from 'utils/util'
import { localStorage } from 'utils/storage'
import product from 'apis/product/product'
import {
  ecSignIn,
  setBeforeLoginPath,
  selectCanGetInitialData,
  selectBeforeLoginPath,
} from 'slices/auth/authSlice'
import { useWindowSize } from 'hooks/useWindowSize'

import Button from 'common/Button'
import Slider from 'common/Slider'
import Separator from 'common/Separator'
import ProductRecommend from 'common/layout/ProductRecommend'
import KeyVision from 'page-components/home/KeyVision'
import PopularTheme from 'page-components/home/PopularTheme'
import GridSlider from 'page-components/home/GridSlider'
import ProductInfoCard from 'page-components/home/ProductInfoCard'
import ProductSimpleCard from 'page-components/home/ProductSimpleCard'
import ProductByKeyword from 'src/common/layout/ProductByKeyword'
import AircraftRunway from 'page-components/home/aircraftRunway'

const handlePopularTourIdList = (popularTourTypeList) => {
  const popularTourIdList = []

  for (const popularTourType of popularTourTypeList) {
    const popularTourTypeProductList = popularTourType?.childrenNodes || []

    for (const popularTourTypeProduct of popularTourTypeProductList) {
      if (Boolean(popularTourTypeProduct.productNumber)) popularTourIdList.push(popularTourTypeProduct.productNumber)
    }
  }

  return popularTourIdList
}

const handlePopularTourDetailList = (popularTourTypeList, popularTourDetailList) => {
  const newPopularTourList = []

  for (const popularTourType of popularTourTypeList) {
    const popularTourTypeProductList = popularTourType?.childrenNodes || []
    const newPopularTourTypeProductList = []
    for (const [productIndex, popularTourTypeProduct] of popularTourTypeProductList.entries()) {
      const popularTourDetail = popularTourDetailList.products.find(popularTourDetailItem => popularTourDetailItem.productNumber === popularTourTypeProduct.productNumber)

      if (Boolean(popularTourDetail)) {
        if (productIndex !== 0) delete popularTourDetail.description

        newPopularTourTypeProductList.push({
          ...popularTourDetail,
          mediaPath: popularTourTypeProduct.imagePath,
        })
      }
    }

    newPopularTourList.push({
      ...popularTourType,
      childrenNodes: newPopularTourTypeProductList,
    })
  }
  return newPopularTourList
}

export async function getServerSideProps(context) {
  const headParams = handleHeadParams(context)

  // kv 資料
  const KV_IMAGE_LIST = await getJsonData(`${ENV_INFO.root}json/home/kv-image-list.json`)

  // 最新旅遊優惠
  const DISCOUNT_LIST = await getJsonData(`${ENV_INFO.root}json/home/discount-list.json`)

  // 熱門國家
  const HOT_TOURS_LIST = await getJsonData(`${ENV_INFO.root}json/home/hot-tours-list.json`)

  // 熱門城市
  const HOT_ADDRESS_LIST = await getJsonData(`${ENV_INFO.root}json/home/hot-address-list.json`)

  return {
    props: {
      ...headParams,
      KV_IMAGE_LIST,
      DISCOUNT_LIST,
      HOT_TOURS_LIST,
      HOT_ADDRESS_LIST,
    }, 
  }
}

const Home = (props) => {
  const { 
    pathQuery,
    KV_IMAGE_LIST,
    DISCOUNT_LIST,
    HOT_TOURS_LIST,
    HOT_ADDRESS_LIST,
  } = props

  const router = useRouter()
  const dispatch = useDispatch()
  const { isWindowWidthLgMax } = useWindowSize()
  const canGetInitialData = useSelector(selectCanGetInitialData)
  const beforeLoginPath = useSelector(selectBeforeLoginPath)

  const [popularTour, setPopularTour] = useState([])
  const [popularTourTab, setPopularTourTab] = useState('')
  const [popularTourList, setPopularTourList] = useState([])
  const [discountList, setDiscountList] = useState(DISCOUNT_LIST)
  const [recommendProducts, setRecommendProducts] = useState({})

  const getRecommendProducts = async (keyword) => {
    try {
      const params = {
        'cityCode': keyword.cityCode,
        'productGroup': ['M001'],
        'productCategories': keyword.type,
        'description': keyword.desc,
        'productPriceMin': 0,
        'productPriceMax': 0,
        'page': 1,
        'take': 10,
        'sortBy': 'Relevant',
        'sortDirection': '',
      }
      const { success, data } = await product.searchProduct(params)
      if (!success) return
      
      const products = data.products?.map(product => ({
        ...product,
        mediaPath: product.mediaPath[0],
        isLocal: keyword.isLocal,
      }))

      setRecommendProducts((prev) => {
        return {
          ...prev,
          [keyword.id]: {
            products,
            title: keyword.title,
            flag: keyword.flag,
            url: keyword.url,
          },
        }
      })
      
    } catch (e) {
      setRecommendProducts((prev) => prev)
    }
  }
  
  const getHomePageProduct = async () => {
    try {
      // FIXME: 後端給的資料格式怪怪的 ( api 資料有 2 層 data， 撈資料會變成 result.data.data.data)，待確認
      const result = await product.getHomePageProduct()
      const { data } = result
      const { treeNodeList } = data.data
      const popularTourParent = treeNodeList?.find(treeNodeItem => treeNodeItem.positionName === 'POPULAR_TOUR')

      if ($isEmpty(popularTourParent?.childrenNodes) === false) {
        const popularTourIdList = handlePopularTourIdList(popularTourParent.childrenNodes)
        const detailResult = await product.getPopularTourProduct(popularTourIdList)
        if ($isEmpty(detailResult?.data) === false) {
          const list = handlePopularTourDetailList(popularTourParent.childrenNodes, detailResult.data)
          setPopularTourTab(list[0].titleName)
          setPopularTourList(list)
        }
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    RECOMMEND_KEYWORDS.forEach((keyword) => {
      getRecommendProducts(keyword)
    })
    
    getHomePageProduct()
  }, [])

  // receive two value from oAuth redirectUri witch getToken api required 
  useEffect(() => {
    if (Object.keys(pathQuery).length === 0 || Boolean(pathQuery.exchange)) return
    router.replace(
      {
        pathname: '/',
        query: pathQuery,
      },
      undefined,
      { shallow: true },
    )
 
    const { code, memberSeq } = pathQuery
    const codeVerifier = localStorage.get(LOCAL_STORAGE.CODE_VERIFIER)
    const stashPathName = window.sessionStorage.getItem(SESSION_STORAGE.STASH_PATH_NAME)

    if (!!code && !!memberSeq && !!codeVerifier) {
      const params = {
        code: code,
        memberSeq: memberSeq,
        codeVerifier: codeVerifier,
      }
      dispatch(setBeforeLoginPath(stashPathName))
      dispatch(ecSignIn(params))
    }
    /* ignore dependency: dispatch & router */
  }, [pathQuery])

  // 導回登入前的頁面 (因和泰集團 OAuth 設定關係，登入後一律導首頁)
  useEffect(() => {
    if (canGetInitialData && Boolean(beforeLoginPath)) {
      router.push(beforeLoginPath)
      dispatch(setBeforeLoginPath(''))
    }

    /* ignore dependency: dispatch */
  }, [beforeLoginPath, canGetInitialData])

  useEffect(() => {
    if (popularTourList?.length) {
      const newPopularTour = popularTourList.find(popularTourItem => popularTourItem.titleName === popularTourTab)
      setPopularTour(newPopularTour?.childrenNodes || [])
    }
  }, [popularTourTab, popularTourList])

  return (
    <>
      <Head>
        <title>{WEB_TITLE}</title>
        <meta name="description" content={HEAD_DEFAULT_DATA.DESC} />
        <link rel="canonical" href={`${ENV_INFO.root}`} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              url: `${ENV_INFO.root}`,
              potentialAction: {
                '@type': 'SearchAction',
                target: `${ENV_INFO.root}search?keyword={keyword}`,
                'query-input': 'required name=keyword',
              },
            }),
          }}
        />
      </Head>

      <h1 className={classReader('d-none')}>{WEB_TITLE}</h1>

      {KV_IMAGE_LIST.length > 0 && <section>
        <KeyVision
          id="key-vision"
          kvList={KV_IMAGE_LIST}
        />
      </section>}

      <main className={classReader({ IndexStyled: ['main'] })}>
        {/* 熱門活動分類 */}
        {isWindowWidthLgMax && <section className={classReader('container', { IndexStyled: ['home-section', 'home-section--theme'] })}>
          <h2 className={classReader('section__title section__title--separator')}>
            熱門活動分類
          </h2>
          <PopularTheme themeList={THEME_OPTIONS} />
        </section>}
     
        {/* 關鍵字推薦 */}
        {RECOMMEND_KEYWORDS.map((keyword, index) => {
          return ((recommendProducts[keyword.id] && recommendProducts[keyword.id]?.products) &&
              <Fragment key={index}>
                <div className={classReader('container', { IndexStyled: ['home-section'] })}>
                  <ProductByKeyword
                    recommendData={recommendProducts[keyword.id].products}
                    title={recommendProducts[keyword.id].title}
                    flag={recommendProducts[keyword.id].flag}
                    url={recommendProducts[keyword.id].url}
                    slider={true}
                    total={10}
                  />
                </div>
                {(index + 1) % 2 === 0 ? <AircraftRunway isLocal={(index + 1) % 4 === 0} /> : '' }
              </Fragment>)})}

        {/* 熱門遊程 */}
        {(
          <section className={classReader(
            'container', { IndexStyled: 'home-section home-section--travel' }, { IndexStyled: { 'home-section--travel__empty': $isEmpty(popularTourList) } },
          )}>
            <div className={classReader('d-md-flex')}>
              <h2 className={classReader('section__title')}>
                <span>熱門遊程</span>
                <Separator className={classReader('col d-none d-md-block ml-2')} color="primary" />
              </h2>

              <div className={classReader('section__title-buttons')}>
                <Slider
                  className={classReader('d-block')}
                  spaceBetween={8}
                  slidesPerView="auto"
                  slidesPerGroup={1}
                  slides={popularTourList.map((type, index) => ({
                    id: `slider${index}`,
                    content: (
                      <Button
                        className={classReader('head__button')}
                        label={type.titleName}
                        color="primary"
                        textColor={popularTourTab === type.titleName ? 'white' : ''}
                        onClick={() => setPopularTourTab(type.titleName)}
                        outline={popularTourTab !== type.titleName}
                        unelevated
                      />
                    ),
                  }))}
                  autoWidth
                />
              </div>
            </div>

            <GridSlider
              productList={popularTour}
              breakpoints={{
                768: {
                  slidesPerView: 2.5,
                  slidesPerGroup: 1,
                  spaceBetween: 20,
                },
                576: {
                  slidesPerView: 2.5,
                  slidesPerGroup: 1,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 2.5,
                  slidesPerGroup: 1,
                  spaceBetween: 10,
                },
                0: {
                  slidesPerView: 1.8,
                  slidesPerGroup: 1,
                  spaceBetween: 10,
                },
              }}
              renderCard={(item) => (
                <ProductInfoCard productInfo={item} />
              )}
              isSlideReset
            />
          </section>
        )}

        {/* 最新旅遊優惠 */}
        <section id="latest-travel-offers" className={classReader('container', { IndexStyled: ['home-section', 'home-section--discount'] })}>
          <h2 className={classReader('section__title section__title--separator')}>
            最新旅遊優惠
          </h2>

          <Slider
            className={classReader('d-block d-lg-flex', { IndexStyled: ['clickable-swiper'] })}
            spaceBetween={20}
            breakpoints={{
              992: {
                slidesPerView: 4,
                slidesPerGroup: 3,
              },
              768: {
                slidesPerView: 3.5,
                slidesPerGroup: 2,
                spaceBetween: 20,
              },
              480: {
                slidesPerView: 2.5,
                slidesPerGroup: 1,
                spaceBetween: 10,
              },
              0: {
                slidesPerView: 1.8,
                slidesPerGroup: 1,
                spaceBetween: 10,
              },
            }}
            slides={discountList.map((item, index) => ({
              id: `slider${index}`,
              content: (
                <ProductSimpleCard
                  productInfo={item}
                  ratio={16 / 9.045}
                  gtmClassName="gtm-latest-travel-offers"
                />
              ),
            }))}
            onBreakpoint={(breakpointEvent) => setDiscountList(prev => {
              const newDiscountList = prev.map(discountItem => ({
                ...discountItem,
                img: breakpointEvent.currentBreakpoint > 768 ? discountItem.img : discountItem.mImg,
              }))
              return newDiscountList
            })}
          />
        </section>

        {/* 熱門國家 */}
        <section className={classReader('container', { IndexStyled: ['home-section', 'home-section--city'] })}>
          <h2 className={classReader('section__title section__title--separator')}>
            熱門國家
          </h2>

          <GridSlider
            productList={HOT_TOURS_LIST}
            breakpoints={{
              768: {
                slidesPerView: 4.5,
                slidesPerGroup: 3,
                spaceBetween: 20,
              },
              576: {
                slidesPerView: 3.5,
                slidesPerGroup: 2,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 2.5,
                slidesPerGroup: 1,
                spaceBetween: 10,
              },
              0: {
                slidesPerView: 1.8,
                slidesPerGroup: 1,
                spaceBetween: 10,
              },
            }}
            renderCard={(item) => (
              <ProductSimpleCard
                productInfo={item}
                ratio={0}
                ratioClassName={classReader({ IndexStyled: 'city-aspect-ratio' })}
                gtmClassName="gtm-tours-city"
              />
            )}
            rightSide
          />
        </section>


        {/* 熱門城市 */}
        <section className={classReader('container', { IndexStyled: ['home-section', 'home-section--city'] })}>
          <h2 className={classReader('section__title section__title--separator')}>
            熱門城市
          </h2>

          <GridSlider
            productList={HOT_ADDRESS_LIST}
            breakpoints={{
              768: {
                slidesPerView: 4.5,
                slidesPerGroup: 3,
                spaceBetween: 20,
              },
              576: {
                slidesPerView: 3.5,
                slidesPerGroup: 2,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 2.5,
                slidesPerGroup: 1,
                spaceBetween: 10,
              },
              0: {
                slidesPerView: 1.8,
                slidesPerGroup: 1,
                spaceBetween: 10,
              },
            }}
            renderCard={(item) => (
              <ProductSimpleCard
                productInfo={item}
                ratio={0}
                ratioClassName={classReader({ IndexStyled: 'city-aspect-ratio' })}
                gtmClassName="gtm-city"
              />
            )}
            leftSide
          />
        </section>

        <div className={classReader('container', { IndexStyled: ['home-section'] })}>
          <ProductRecommend
            slider={true}
            total={6}
          />
        </div>
      </main>
    </>
  )
}

Home.propTypes = { pathQuery: PropTypes.object }
Home.defaultProps = { pathQuery: {} }

export default Home