import {
  useRef, useEffect, useState, 
} from 'react'
import PropTypes from 'prop-types'
import Image from 'next/image'
import classReader from 'utils/classReader'
import aircraft from 'public/aircraft.svg'
import aircraftLocal from 'public/aircraft-local.svg'

const AircraftRunway = (props) => {
  const { isLocal } = props
  const [isFirstTime, setIsFirstTime] = useState(true)
  const runway = useRef(null)

  const handleRunwayClicked = () => {
    runway.current.classList.add(classReader({ AircraftRunwayStyled: 'active' }))
  }

  useEffect(() => {
    const removeActive = () => {
      runway.current.classList.remove(classReader({ AircraftRunwayStyled: 'active' }))
    }

    const autoPilot = () => {
      if (isFirstTime) setIsFirstTime(false)
      if (runway.current && !runway.current.classList.contains('active')) {
        runway.current.classList.add(classReader({ AircraftRunwayStyled: 'active' }))
      }
    }

    let intervalId = 0

    if (runway && runway.current){
      runway.current.addEventListener(
        'animationend', removeActive, false,
      )
      intervalId = setInterval(autoPilot, isFirstTime ? 5000 : 20000)
    }
    
    return () => {
      clearInterval(intervalId)
      if (runway && runway.current){
        runway.current.removeEventListener(
          'animationend', removeActive, false,
        )
      }
    }
  }, [isFirstTime])

  return (
    <div className={classReader('overflow-hidden', { AircraftRunwayStyled: 'runway' })} ref={runway} onClick={handleRunwayClicked}>
      <div className={classReader({ AircraftRunwayStyled: 'white-line white-line-area' })}>
        <div className={classReader({ AircraftRunwayStyled: isLocal ? 'white-line-marquee-local' : 'white-line-marquee' }, { AircraftRunwayStyled: 'white-line-marquee-style' })}>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
        </div>
        <div className={classReader({ AircraftRunwayStyled: isLocal ? 'white-line-marquee-local' : 'white-line-marquee' }, { AircraftRunwayStyled: 'white-line-marquee-style' })}>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
        </div>
        <div className={classReader({ AircraftRunwayStyled: isLocal ? 'white-line-marquee-local' : 'white-line-marquee' }, { AircraftRunwayStyled: 'white-line-marquee-style' })}>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
        </div>
        <div className={classReader({ AircraftRunwayStyled: isLocal ? 'white-line-marquee-local' : 'white-line-marquee' }, { AircraftRunwayStyled: 'white-line-marquee-style' })}>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
        </div>
        <div className={classReader(classReader({ AircraftRunwayStyled: isLocal ? 'white-line-marquee-local' : 'white-line-marquee' }, { AircraftRunwayStyled: 'white-line-marquee-style' }))}>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
        </div>
        <div className={classReader(classReader({ AircraftRunwayStyled: isLocal ? 'white-line-marquee-local' : 'white-line-marquee' }, { AircraftRunwayStyled: 'white-line-marquee-style' }))}>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
        </div>
        <div className={classReader(classReader({ AircraftRunwayStyled: isLocal ? 'white-line-marquee-local' : 'white-line-marquee' }, { AircraftRunwayStyled: 'white-line-marquee-style' }))}>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
        </div>
        <div className={classReader(classReader({ AircraftRunwayStyled: isLocal ? 'white-line-marquee-local' : 'white-line-marquee' }, { AircraftRunwayStyled: 'white-line-marquee-style' }))}>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
        </div>
        <div className={classReader(classReader({ AircraftRunwayStyled: isLocal ? 'white-line-marquee-local' : 'white-line-marquee' }, { AircraftRunwayStyled: 'white-line-marquee-style' }))}>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
        </div>
        <div className={classReader(classReader({ AircraftRunwayStyled: isLocal ? 'white-line-marquee-local' : 'white-line-marquee' }, { AircraftRunwayStyled: 'white-line-marquee-style' }))}>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
          <div className={classReader({ AircraftRunwayStyled: 'line' })}></div>
        </div>
      </div>
      <div className={classReader({ AircraftRunwayStyled: isLocal ? 'aircraft-local' : 'aircraft' }, { AircraftRunwayStyled: 'aircraft-style' })}>
        <Image src={isLocal ? aircraftLocal : aircraft} alt="aircraft" />
      </div>
    </div>
  )
}


AircraftRunway.propTypes = { isLocal: PropTypes.bool }
AircraftRunway.defaultProps = { isLocal: false }

export default AircraftRunway
