import { memo } from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'
// import IndexStyled from 'styles/pages/home/index.module.scss'

import Card from 'common/card/Card'
import CardSection from 'common/card/CardSection'
import Img from 'common/Img'
import Slider from 'common/Slider'
import SwiperButton from 'page-components/home/SwiperButton'

const FIGURE_ICON = 32

const PopularTheme = (props) => {
  const { themeList } = props

  const filterThemeList = themeList.reduce((result, item) => {
    if (Boolean(item.img)) {
      return [...result, item]
    } else {
      return result
    }
  }, [])
  return (
    <>
      <div className={classReader('d-none d-md-block')}>
        <Slider
          className={classReader('d-block d-lg-flex', { IndexStyled: ['clickable-swiper'] })}
          spaceBetween={20}
          breakpoints={{
            1200: {
              slidesPerView: 6,
              slidesPerGroup: 3, 
            },
            992: {
              slidesPerView: 5,
              slidesPerGroup: 3, 
            },
            768: {
              slidesPerView: 4.5,
              slidesPerGroup: 3, 
            },
          }}
          slides={filterThemeList.map((item, index) => ({
            id: `slider${index}`,
            content: (
              <Link
                prefetch={false}
                href={item.link || '/'}
                aria-label={item.label}
                passHref
                className={classReader({ pure: 'gtm-popular-theme' })}>
                <Card className={classReader('shadow-0', { IndexStyled: 'card--theme' })}>
                  <Img
                    className={classReader('rounded-ec', { IndexStyled: 'img' })}
                    src={item.img}
                    alt={item.label}
                  />
                  <CardSection className={classReader('px-0 py-2')}>
                    {item.label}
                  </CardSection>
                </Card>
              </Link>
            ),
          }))}
          customLeftNavigation={(prevFunc) => (
            <SwiperButton icon="stroke-arrow-left" onClick={prevFunc} />
          )}
          customRightNavigation={(nextFunc) => (
            <SwiperButton icon="stroke-arrow-right" onClick={nextFunc} />
          )}
          buttonOutside
        />
      </div>

      <div className={classReader('d-md-none')}>
        <div className={classReader('d-grid', { IndexStyled: ['home-section--grid'] })}>
          {filterThemeList.map((item, index) => (
            <figure key={index} className={classReader({ IndexStyled: 'home-section__figure' })}>
              <Link
                className={classReader({ pure: 'gtm-popular-theme' })}
                prefetch={false}
                href={item.link || '/'} 
                aria-label={item.label}
                passHref
              >
                <Img
                  className={classReader('rounded-ec', { IndexStyled: ['home-section__figure-icon'] })}
                  src={item.icon}
                  alt={item.label}
                  height={FIGURE_ICON}
                  width={FIGURE_ICON}
                />
                <figcaption className={classReader({ IndexStyled: ['home-section__figure-label'] })}>
                  {item.label}
                </figcaption>
              </Link>
            </figure>
          ))}
        </div>
      </div>
    </>
  )
}

PopularTheme.propTypes = { themeList: PropTypes.array }

PopularTheme.defaultProps = { themeList: [] }

export default memo(PopularTheme)
