import { memo } from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'

import Slider from 'common/Slider'

const GridSlider = (props) => {
  const {
    productList,
    breakpoints,
    rightSide,
    isSlideReset,
    renderCard,
    onBreakpoint,
  } = props

  const mainSide = rightSide === true ? classReader({ IndexStyled: ['product--right-side'] }) : classReader({ IndexStyled: ['product'] })
  return (
    <>
      <div className={classReader('d-none d-lg-grid', { IndexStyled: ['home-section--grid'] })}>
        {productList.map((item, index) => (
          <div key={index} className={classReader(mainSide)}>
            {renderCard(
              item, index, true,
            )}
          </div>
        ))}
      </div>

      <div className={classReader('d-lg-none')}>
        <Slider
          spaceBetween={20}
          breakpoints={breakpoints}
          isSlideReset={isSlideReset}
          slides={productList.map((item, index) => ({
            id: `slider${index}`,
            content: renderCard(item, index),
          }))}
          onBreakpoint={onBreakpoint}
        />
      </div>
    </>
  )
}

GridSlider.propTypes = {
  productList: PropTypes.array,
  breakpoints: PropTypes.object,
  rightSide: PropTypes.bool,
  isSlideReset: PropTypes.bool,
  renderCard: PropTypes.func,
  onBreakpoint: PropTypes.func,
}

GridSlider.defaultProps = {
  productList: [],
  breakpoints: {},
  rightSide: false,
  isSlideReset: false,
  renderCard: () => { },
  onBreakpoint: (breakpointEvent) => { },
}

export default memo(GridSlider)
