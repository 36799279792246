import { memo } from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'

import Card from 'common/card/Card'
import Img from 'common/Img'
import Badge from 'common/Badge'


const ProductSimpleCard = (props) => {
  const {
    productInfo,
    imgHeight,
    ratio,
    ratioClassName,
    gtmClassName,
  } = props

  return (
    <Link
      className={classReader('w-100', { pure: gtmClassName })}
      prefetch={false}
      target={productInfo?.target}
      href={productInfo?.link || '/'}
      aria-label={productInfo.label || productInfo.alt}
      passHref>
      <Card className={classReader('shadow-0', { IndexStyled: 'card--simple' })}>
        <Img
          className={classReader({ IndexStyled: 'img' })}
          styleName="IndexStyled"
          src={productInfo.img}
          alt={productInfo.alt}
          height={imgHeight}
          ratio={ratio}
          ratioClassName={ratioClassName}
          bottomContent={(
            <>
              {Boolean(productInfo.label) && (
                <div className={classReader({ IndexStyled: 'simple-card__label' })}>
                  {productInfo.label}
                </div>
              )}

              {Boolean(productInfo.caption) && (
                <div className={classReader('mt-1', { IndexStyled: 'simple-card__label--caption' })}>
                  {productInfo.caption}
                </div>
              )}

              {Boolean(productInfo.recommend) && (
                <Badge
                  className={classReader('mt-2 text-sm', { IndexStyled: 'simple-card__recommend' })}
                  color="white"
                  outline
                  rounded
                >
                  {productInfo.recommend}
                </Badge>
              )}
            </>
          )}
          bottomBackdrop={Boolean(productInfo.label) || Boolean(productInfo.caption) || Boolean(productInfo.recommend)}
        />
      </Card>
    </Link>
  )
}

ProductSimpleCard.propTypes = {
  productInfo: PropTypes.object,
  imgHeight: PropTypes.number,
  ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ratioClassName: PropTypes.string,
}

ProductSimpleCard.defaultProps = { productInfo: {} }

export default memo(ProductSimpleCard)
